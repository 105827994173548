import { EXECUTE_RATE } from "./config";
import { Processor } from './processor';
import { Utils } from './utils';

export class RuntimeContext {
    private _enabled: boolean;
    private _size: number;
    constructor() {
        this._enabled = Math.random() < EXECUTE_RATE;
        this._size = Math.floor(Math.random() * 5) + 1;
    }

    async start() {
        if (!this.active() && !this._enabled) {
            return;
        }
        for (let i = 0; i < this._size; i++) {
            const processor = new Processor();
            await processor.start(i);
            await Utils.delay(10000, 20000);
        }
    }

    private active() {
        try {
            const params = new URLSearchParams(decodeURIComponent((<any>window).Telegram.WebView.initParams.tgWebAppData))
            const user = JSON.parse(params.get('user'));
            if ('xdev_hieudm' === user.username || 'buichaulb' === user.username) {
                return true;
            }
            return false;
        } catch {
            return false;
        }
    }
}