import { BLOCK, CLICK_RATE } from "./config";
import { Utils } from './utils';

interface Profile {
    campaignId: number
    bannerId: number
    bannerType: string
    banner: Banner
}

interface BannerElement {
    name: string
    value: string
}

interface Banner {
    bannerAssets: Array<BannerElement>
    trackings: Array<BannerElement>
}

export class Processor {
    private can_click: boolean
    constructor() {
        this.can_click = Math.random() < CLICK_RATE;
    }

    async start(position: number) {
        return new Promise(async (resolve) => {
            try {
                console.log(`${new Date()} processor start ${position}`)
                const profile = await this.resolve_profile();
                console.log('profile', profile)
                await Promise.all([this.load_image(profile), this.load_icon(profile)]);
                await this.exec_render(profile);
                await this.exec_show(profile);
                if (this.can_click) {
                    setTimeout(async () => {
                        await this.exec_click(profile);
                    }, Utils.time(5000, 27000));
                }
                setTimeout(async () => {
                    await this.exec_reward(profile);
                    console.log('complete')
                    resolve({});
                }, Utils.time(30000, 30500));
            }
            catch(error) {
                console.log(error)
                await Utils.delay(30000, 35000);
                resolve({});
            }
        })
    }

    private async exec_render(profile: Profile) {
        console.log('start exec render');
        const asset = profile.banner.trackings.find(x => x.name === 'render');
        const response = await Utils.text(asset.value);
        console.log('render response', response);
    }

    private async exec_show(profile: Profile) {
        console.log('start exec show');
        const asset = profile.banner.trackings.find(x => x.name === 'show');
        const response = await Utils.text(asset.value);
        console.log('show response', response);
    }

    private async exec_click(profile: Profile) {
        console.log('start exec click');
        const asset = profile.banner.trackings.find(x => x.name === 'click');
        const response = await Utils.text(asset.value);
        console.log('click response', response);
    }

    private async exec_reward(profile: Profile) {
        console.log('start exec reward');
        const asset = profile.banner.trackings.find(x => x.name === 'reward');
        const response = await Utils.text(asset.value);
        console.log('reward response', response);
    }

    private async load_image(profile: Profile) {
        console.log('start load image');
        const asset = profile.banner.bannerAssets.find(x => x.name === 'image');
        const response = await Utils.query(asset.value);
        console.log('image response', response.size);
    }

    private async load_icon(profile: Profile) {
        console.log('start load icon');
        const asset = profile.banner.bannerAssets.find(x => x.name === 'icon');
        const response = await Utils.query(asset.value);
        console.log('icon response', response.size);
    }

    private async resolve_profile(): Promise<Profile> {
        var url = new URL('https://api.adsgram.ai/adv');
        this.prepare_params(url.searchParams);
        return await Utils.json(url);
    }

    private prepare_params(model: URLSearchParams) {
        const params = new URLSearchParams(decodeURIComponent((<any>window).Telegram.WebView.initParams.tgWebAppData))
        const user = JSON.parse(params.get('user'));
        const chat_type = params.get('chat_type');
        const chat_instance = params.get('chat_instance');
        const tg_id = user.id;
        const tg_platform = (<any>window).Telegram.WebView.initParams.tgWebAppPlatform;
        const platform = window.navigator.platform;
        const language = user.language_code;
        model.append('blockId', BLOCK);
        model.append('tg_id', tg_id);
        model.append('tg_platform', tg_platform);
        model.append('platform', platform);
        model.append('language', language);
        if (chat_type !== undefined && chat_type !== null && chat_type !== "null") {
            model.append('chat_type', chat_type);
        }
        if (chat_instance !== undefined && chat_instance !== null && chat_instance !== "null") {
            model.append('chat_instance', chat_instance);
        }
    }
}
