export class Utils {
    static delay(from_ms: number, to_ms: number) {
        const time = Utils.time(from_ms, to_ms);
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({});
            }, time);
        });
    }

    static time(from_ms: number, to_ms: number) {
        return Math.floor(Math.random() * (to_ms - from_ms)) + from_ms;
    }

    static async query(url: string | URL) {
        const response = await fetch(url);
        return await response.blob();
    }

    static async json(url: string | URL) {
        const response = await fetch(url);
        return await response.json();
    }

    static async text(url: string | URL) {
        const response = await fetch(url);
        return await response.text();
    }
}